<template>
  <div>
    <div class="header">
      <div class="header_top">
        当前位置：
        <span class="center">人资管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">待分配员工列表</span>
      </div>
    </div>
    <div class="backCard">
      <div style="display: flex; align-items: center; flex-wrap: wrap">
        <div>
          <span class="seach_text">姓名：</span>
          <el-input
            placeholder="请输入姓名"
            v-model="formInline.f_like_name"
            size="mini"
            style="margin-right: 10px; width: 150px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
        <div>
          <span class="seach_text">手机号：</span>
          <el-input
            placeholder="请输入手机号"
            v-model="formInline.f_like_username"
            size="mini"
            style="margin-right: 10px; width: 150px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>

        <el-button
          size="small"
          style="margin: 10px; border-radius: 5px"
          type="primary"
          @click="seach"
        >查询</el-button>
      </div>
      <div>
        <el-button type="primary" @click="getUserAllocated">我的分配</el-button>
        <el-button type="primary" :disabled="selectShow" @click="getMoreAllocated">批量分配</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="员工姓名">
        <template slot-scope="scope">{{ scope.row.name }}</template>
      </el-table-column>

      <el-table-column label="手机号">
        <template slot-scope="scope">
          <div v-if="scope.row.username" class="clickColor">
            {{
            scope.row.username
            }}
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- <el-button size="mini" type="text" @click="getDetail(scope.row)">详情</el-button> -->
          <el-button size="mini" type="text" @click="getAllocated(scope.row)">分配</el-button>
        </template>
      </el-table-column>
    </el-table>
    <allocatedDialog
      :addDialogVisible.sync="addDialogVisible"
      :code.sync="code"
      :codeList.sync="codeList"
      :title.sync="title"
      @func="addclose()"
    ></allocatedDialog>
    <userAllocatedDialog :dialogVisible.sync="dialogVisible" @func="addclose()"></userAllocatedDialog>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const staffBaseUser = api()('staff.baseUser.json')

export default {
  components: {
    allocatedDialog: () => import('./allocatedDialog'),
    userAllocatedDialog: () => import('./userAllcated')
  },
  data () {
    return {
      formInline: {
        f_like_name: '',
        f_like_username: '',

      },
      tableData: [],
      codeList: [],
      pageNumber: 1,
      pageSize: 10,
      addDialogVisible: false,
      code: '',
      title: '',
      selectShow: true,
      dialogVisible: false,
      total: 0
    }
  },
  created () {
    this.loadData()
  },
  methods: {

    getUserAllocated () {
      this.dialogVisible = true
    },
    addclose () {

      this.loadData()
    },
    seach () {
      this.pageNumber = 1;
      this.loadData();
    },
    getMoreAllocated () {

      this.title = '批量分配'
      this.addDialogVisible = true
    },
    handleSelectionChange (val) {
      this.codeList = val.map((it) => ({
        code: it.code,
        name: it.name
      }
      ))
      console.log(this.codeList);
      this.selectShow = !val.length

    },
    getAllocated (row) {
      this.code = row.code
      this.title = '员工分配'
      this.addDialogVisible = true

    },
    handleSizeChange (val) {
      this.pageSize = val
      this.loadData()
    },
    handleCurrentChange (val) {
      this.pageNumber = val
      this.loadData()
    },
    loadData () {
      const params = {
        ...this.formInline,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
      staffBaseUser(params).then(res => {
        this.tableData = res.list
        this.total = res.total
      }).catch(err => {
        console.log(err);
      })

    }
  },
}
</script>
<style lang="scss">
.backCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>